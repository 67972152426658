import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Rome - Europe | Kiwi & Cacahuate"
      description="5 week trip to Europe"
      url="https://kiwicacahuate.com/rome/"
      image="https://kiwicacahuate.com/rome/rome.jpg"
      imageWidth="4000"
      imageHeight="2668"
    />

    <div className="">
      <div className="story">
        <div className="photoset-section">
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/rome.webp, /rome/rome.webp 2x" />
                <source srcSet="/rome/rome.jpg, /rome/rome.jpg 2x" />
                <img src="/rome/rome.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
